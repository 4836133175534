var chatButtonId = 'liveagent_button_online_5738F000000GmdD';

const changeChatChannel = (isSalesChatOpened, baseCoreUrl, communityEndpointURL, orgId, eswConfigDevName, baseLiveAgentContentURL, buttonId, baseLiveAgentURL, eswLiveAgentDevName) =>
{
  localStorage.setItem('isSalesChatOpened', isSalesChatOpened, 1);
  localStorage.setItem('isChatClicked', true, 1);

  window.embedded_svc.settings.baseCoreURL = baseCoreUrl;
  window.embedded_svc.settings.communityEndpointURL = communityEndpointURL;
  window.embedded_svc.settings.orgId = orgId;
  window.embedded_svc.settings.eswConfigDevName = eswConfigDevName;
  window.embedded_svc.settings.baseLiveAgentContentURL = baseLiveAgentContentURL;
  window.embedded_svc.settings.buttonId = buttonId;
  window.embedded_svc.settings.baseLiveAgentURL = baseLiveAgentURL;
  window.embedded_svc.settings.eswLiveAgentDevName = eswLiveAgentDevName;
  window.location.reload();
}

function clickChat()
{
  document.getElementById(chatButtonId).click();
}

const goToChat = () =>
{
  let chatButtons = document.getElementsByClassName('helpButtonEnabled');

  if (chatButtons)
  {
    let originalChatButton = chatButtons[0];
    originalChatButton.click();
  }
}

const invisibleChatButtonClickHandler = () =>
{
  document.getElementById('preChat').style.display = 'block';

  let minimizedChatButton = document.getElementsByClassName('helpButton');

  if (minimizedChatButton[1])
  {
    minimizedChatButton[1].click();
  }
  else
  {
    showModal();
  }
}

const showModal = () =>
{
  document.getElementById(chatButtonId).style.display = 'none';
  document.getElementById('preChat').style.display = 'block';
}

const hideModal = () =>
{
  document.getElementById('preChat').style.display = 'none';
  document.getElementById(chatButtonId).style.display = 'block';
}

function hideModalOnMinimize(isChatWindowHidden)
{
  document.getElementById('preChat').style.display = 'none';

  if (isChatWindowHidden)
  {
    window.embedded_svc.showHelpButton();
  } else
  {
    window.embedded_svc.hideHelpButton();
  }
}

function loadChatScripts()
{
  if (document.getElementById("chatSalesForce") === null)
  {
    const eswJs = document.createElement('script');
    eswJs.setAttribute("src", "https://service.force.com/embeddedservice/5.0/esw.min.js");
    eswJs.setAttribute("type", "text/javascript");
    eswJs.setAttribute("id", "chatSalesForce");
    eswJs.addEventListener('load', overrideSalesforce, false);
    eswJs.addEventListener('error', tryFallback, false);
    document.body.appendChild(eswJs);
  }
}

function overrideSalesforce()
{
  window.embedded_svc.addEventHandler("afterMaximize", () => { hideModalOnMinimize(false); });
  window.embedded_svc.addEventHandler("afterDestroy", () =>
  {
    localStorage.setItem('isChatClicked', false, 1);
    document.getElementById(chatButtonId).style.display = 'block';
  });

  window.embedded_svc.addMessageHandler("session.onLoad", function ()
  {
    window.embedded_svc.utils.addEventHandler('onSettingsCallCompleted', function ()
    {
      var contentBtnChat = document.getElementsByClassName('embeddedServiceHelpButton');
      if (contentBtnChat && contentBtnChat[0])
      {
        var button = contentBtnChat[0].getElementsByClassName('helpButtonEnabled');
        if (button && button[0])
        {
          button[0].addEventListener("click", function ()
          {
            let readyEvents = window.embedded_svc.utils.eventHandlers['ready'];
            if (readyEvents && readyEvents.indexOf(addPrivacyNotice) >= 0)
            {
              return;
            }
            window.embedded_svc.utils.addEventHandler('ready', addPrivacyNotice);
          });
        }
      }
      let isChatClicked = localStorage.getItem('isChatClicked');
      if (isChatClicked === 'true')
      {
        goToChat();
      }
    });
  });
  initESW('https://service.force.com');
}

function tryFallback()
{
  var s = document.createElement('script');
  s.setAttribute('src', 'https://nic20.my.salesforce.com/embeddedservice/5.0/esw.min.js'); //CHAT DE PRODUCCION
  s.onload = function ()
  {
    initESW(null);
  };
  document.body.appendChild(s);
}

const initESW = function (gslbBaseURL)
{
  var chatLanguageCode = document.getElementById('chatLanguageCode').innerHTML;
  var chatDefaultMinimizedText = document.getElementById('chatDefaultMinimizedText').innerHTML;
  var chatDisabledMinimizedText = document.getElementById('chatDisabledMinimizedText').innerHTML;
  var chatLoadingText = document.getElementById('chatLoadingText').innerHTML;

  window.embedded_svc.settings.language = chatLanguageCode;
  window.embedded_svc.settings.defaultMinimizedText = chatDefaultMinimizedText;
  window.embedded_svc.settings.disabledMinimizedText = chatDisabledMinimizedText;
  window.embedded_svc.settings.offlineSupportMinimizedText = chatDisabledMinimizedText;
  window.embedded_svc.settings.loadingText = chatLoadingText;
  window.embedded_svc.settings.displayHelpButton = true;
  window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  window.embedded_svc.settings.entryFeature = 'LiveAgent';

  let isSalesChatActive = localStorage.getItem('isSalesChatOpened');

  if (isSalesChatActive === 'true')
  {
    window.embedded_svc.init(
      'https://nic20.my.salesforce.com',
      'https://comunidad-nic.force.com/ayudaakky',
      gslbBaseURL,
      '00D5w000000Z3w5',
      'Agentes_de_Ventas_AKKY',
      {
        baseLiveAgentContentURL: 'https://c.la1-c2-ia5.salesforceliveagent.com/content',
        deploymentId: '5725w000000QACy',
        buttonId: '5735w000000LynZ',
        baseLiveAgentURL: 'https://d.la1-c2-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5w000000H2rpEAC_1798ae97413',
        isOfflineSupportEnabled: true
      }
    );
  }
  else
  {
    window.embedded_svc.init(
      'https://nic20.my.salesforce.com',
      'https://site-nic.secure.force.com/liveAgentSetupFlow',
      gslbBaseURL,
      '00D5w000000Z3w5',
      'Agentes_de_chat_AKKY',
      {
        baseLiveAgentContentURL: 'https://c.la1-c2-ia5.salesforceliveagent.com/content',
        deploymentId: '5725w000000QACy',
        buttonId: '5735w000000QAy6',
        baseLiveAgentURL: 'https://d.la1-c2-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'Agentes_de_chat_AKKY',
        isOfflineSupportEnabled: true
      }
    );
  }
}

function addPrivacyNotice()
{
  let contentFormularioOffline = document.getElementsByClassName('offlineSupportUI embeddedServiceLiveAgentStateOfflineSupportDefaultUI');

  if (contentFormularioOffline && contentFormularioOffline[0])
  {
    let formularioOffline = contentFormularioOffline[0].getElementsByClassName('formContent embeddedServiceSidebarForm');
    let buttonEnviar = contentFormularioOffline[0].getElementsByClassName('embeddedServiceSidebarButton');

    if (buttonEnviar && buttonEnviar[0])
    {
      buttonEnviar[0].disabled = true;
    }

    if (formularioOffline && formularioOffline[0])
    {
      let avisoPrivacidad = document.createElement('div');

      avisoPrivacidad.id = 'avisoPrivacidad';
      avisoPrivacidad.classList.add('avisoPrivacidad');
      avisoPrivacidad.style = 'margin-bottom: 15px;';
      avisoPrivacidad.innerHTML = document.getElementById('privacy-notice').innerHTML;

      formularioOffline[0].appendChild(avisoPrivacidad);

      let privacyCheckButton = avisoPrivacidad.getElementsByTagName('input')[0];

      privacyCheckButton.addEventListener('click', function ()
      {
        this.getElementsByClassName('embeddedServiceSidebarButton')[0].disabled = !privacyCheckButton.checked;
      }.bind(contentFormularioOffline[0], this));
    }
  }
}

function loadEvents()
{
  let invisibleChatButton = document.getElementById(chatButtonId);
  invisibleChatButton.addEventListener('click', invisibleChatButtonClickHandler);

  let salesChatButton = document.getElementById('liveagent_button_online_5735w000000LynZ');
  salesChatButton.addEventListener('click',
    () => changeChatChannel(
      true,
      'https://nic20.my.salesforce.com',
      'https://comunidad-nic.force.com/ayudaakky',
      '00D5w000000Z3w5',
      'Agentes_de_Ventas_AKKY',
      'https://c.la1-c2-ia5.salesforceliveagent.com/content',
      '5735w000000LynZ',
      'https://d.la1-c2-ia5.salesforceliveagent.com/chat',
      'EmbeddedServiceLiveAgent_Parent04I5w000000H2rpEAC_1798ae97413'
    )
  );

  let supportChatButton = document.getElementById('liveagent_button_online_5735w000000QAy6');
  supportChatButton.addEventListener('click',
    () => changeChatChannel(
      false,
      'https://nic20.my.salesforce.com',
      'https://site-nic.secure.force.com/liveAgentSetupFlow',
      '00D5w000000Z3w5',
      'Agentes_de_chat_AKKY',
      'https://c.la1-c2-ia5.salesforceliveagent.com/content',
      '5735w000000QAy6',
      'https://d.la1-c2-ia5.salesforceliveagent.com/chat',
      'Agentes_de_chat_AKKY'
    )
  );

  let isChatClicked = localStorage.getItem('isChatClicked');

  if (isChatClicked === 'true')
  {
    document.getElementById(chatButtonId).style.display = 'none';
    loadChatScripts();
  }
}
